import {inject, Injectable} from "@angular/core";
import {AuthService, UserDto} from "@ara-ui/libs/backend";
import {UserLogin} from "@ara-ui/identity/utils-types";
import {Observable} from "rxjs";
import {ApiAuthLoginPost$Plain$Params} from "../../../../../backend/api/fn/auth/api-auth-login-post-plain";

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {
  private readonly authApiService = inject(AuthService);

  private mappingLoginInAuthLoginPost(userLogin: UserLogin): ApiAuthLoginPost$Plain$Params {
    const userDto: UserDto = {
      email: userLogin.email,
      password: userLogin.password
    }
    return {
      body: userDto
    };
  }

  loginAuthentication(userLogin: UserLogin): Observable<string> {
    return this.authApiService.apiAuthLoginPost$Plain(this.mappingLoginInAuthLoginPost(userLogin));
  }

  registerNewUser(registerData: UserLogin): Observable<string> {
    return this.authApiService.apiAuthRegisterPost$Plain(this.mappingLoginInAuthLoginPost(registerData));
  }
}
