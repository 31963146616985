import {ApplicationConfig} from '@angular/core';
import {provideRouter} from '@angular/router';
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {provideAnimations} from '@angular/platform-browser/animations'
import {routes} from './app.routes';
import {jwtInterceptor} from "@ara-ui/identity/data-access";
import {DialogService} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([jwtInterceptor])),
    provideAnimations(),
    DialogService,
    MessageService
  ]
};
