import {HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {inject} from "@angular/core";
import {AuthFacadeService} from "./facades/auth-facade.service";

export const jwtInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authToken = inject(AuthFacadeService).token();
  if (authToken) {
    const reqWithHeaders = req.clone({
      setHeaders: {
        'Authorization': `Bearer ${authToken}`
      }
    })
    return next(reqWithHeaders);
  }
  return next(req);
};
