import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {PrimeNGConfig} from 'primeng/api';
import {ApiConfiguration} from "../../../../libs/backend/api/api-configuration";
import {environment} from "../environments/environment";
import {ToastModule} from "primeng/toast";


@Component({
  selector: 'ara-ui-root',
  standalone: true,
  template: `
    <router-outlet/>
  `,
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterOutlet, ToastModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'ImmoForge';
  apiConfig: ApiConfiguration = inject(ApiConfiguration);
  primengConfig: PrimeNGConfig = inject(PrimeNGConfig);

  constructor() {
    console.log("apiUrl: " + environment.apiUrl);
    this.apiConfig.rootUrl = environment.apiUrl;
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }
}
