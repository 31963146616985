import {Routes} from '@angular/router';
import {authGuard} from "@ara-ui/identity/data-access";

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    loadChildren: () => import('@ara-ui/identity/routes').then(m => m.routes)
  },
  {
    path: 'home',
    loadComponent: () => import('./home/feature-home.component').then(m => m.FeatureHomeComponent),
    canActivate: [authGuard],
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];
