import {computed, Injectable, signal, Signal, WritableSignal} from '@angular/core';
import {jwtDecode} from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthFacadeService {
  private tokenSignal: WritableSignal<string> = signal<string>("");
  readonly token: Signal<string> = this.tokenSignal.asReadonly();

  readonly tokenExpiryTimeSignal: Signal<number> = computed(
    () => {
      return jwtDecode(this.token()).exp ?? 0
    }
  );
  readonly tokenIsExpiredSignal: Signal<boolean> = computed(
    () => {
      if (this.tokenExpiryTimeSignal()) {
        return ((1000 * this.tokenExpiryTimeSignal()) - (new Date()).getTime()) < 5000
      }
      return true
    }
  );

  setSessionToken(token: string): void {
    this.tokenSignal.set(token);
  }
}
