import {computed, inject, Injectable, Signal, signal, WritableSignal} from '@angular/core';
import {AuthDataService} from "../data-services/auth-data.service";
import {Observable, of} from "rxjs";
import {UserLogin} from "@ara-ui/identity/utils-types";

@Injectable({
  providedIn: 'root'
})
export class LoginFacadeService {
  private readonly authDataService: AuthDataService = inject(AuthDataService);

  private userLoginSignal: WritableSignal<UserLogin> = signal<UserLogin>({
    email: "",
    password: ""
  })
  readonly userLogin = this.userLoginSignal.asReadonly();

  public readonly user: Signal<string> = computed(
    () => this.userLoginSignal().email
  );

  public password: Signal<string> = computed(
    () => this.userLoginSignal().password
  );

  verifyLogin(userLogin: UserLogin): Observable<string> {
    if (!userLogin.email || !userLogin.password) return of("");

    return this.authDataService.loginAuthentication(userLogin);
  }

  registerUser(registerUser: UserLogin): Observable<string> {
    return this.authDataService.registerNewUser(registerUser);
  }

  updateUserLogin(login: UserLogin): void {
    this.userLoginSignal.update(
      (userLogin) => (
        {
          ...userLogin,
          email: login.email,
          password: login.password
        }
      )
    )
  }
}
