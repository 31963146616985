import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {AuthFacadeService} from "./facades/auth-facade.service";

export const authGuard: CanActivateFn = () => {
  const authFacade = inject(AuthFacadeService);
  if (authFacade.tokenIsExpiredSignal()) {
    inject(Router).navigate(['/login']);
    return false;
  } else {
    return true;
  }
};
/*
* G U A R D S
* wichtig zu klären für 100% nutzung <- wie benutze ich den message service von primeng ! aktuell keine lösung
* */

/*
* I N T E R C E PT O R
* - header token setzen, wenn vorhanden
* - zentrales error handling
* */


// TODO: interceptor werden bei httprequests / responses gerufen
// TODO: wer fragt auf den token ab? auf den expiry date?
// TODO: siehe: https://angular.dev/guide/http/interceptors#modifying-requests

